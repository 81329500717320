import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const GridButtonCall = styled(Grid)`
    background-color: #293252;
    border-radius: 4px 4px 4px 4px;
    height: 40%;
`

export const GridButtonCallCustomer = styled(Grid)`
    background-color: #293252;
    border-radius: 4px 4px 4px 4px;
    height: 52%;
    position: relative;
`

export const DivLogout = styled.div`
    position: absolute;
    bottom: 0;
`

export const GridScreenOperator = styled(Grid)`
    background-color: #F2F2F2;
    border-radius: 4px 4px 4px 4px;
    height: 40%;
    flex-direction: column;
`

export const GridScreenUser = styled(Grid)`
    background-color: #F2F2F2;
    border-radius: 4px 4px 4px 4px;
    height: 52%;
    flex-direction: column;
`

export const GridQueueCustomer = styled.div`
    background-color: #A2A6B9;
    border-radius: 4px 4px 4px 4px;
`

export const GridQueue = styled(Grid)`
    background-color: #A2A6B9;
    border-radius: 4px 4px 4px 4px;
    height: 7%;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

export const DivRightSide = styled(Grid)`
    display: flex;
    justify-content: center;
    flex-direction: column;
`

export const TextCustom = styled.span`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-family: 'Roboto', sans-serif;
    font-weight: ${props => props.bold && "bold"};
    margin-top: 30px;

`

export const TextCustomCustomer = styled.span`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-family: 'Roboto', sans-serif;
    font-weight: ${props => props.bold && "bold"};
    display: flex;
    text-align: left;
    padding-top: 10px;

`

export const SpanCustom = styled.span`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-family: 'Roboto', sans-serif;
    /* font-weight: none; */
    font-weight: ${props => props.bold && "bold"};
    display: flex;
    text-align: left;
    padding-top: 10px;
`

export const SpanCustomQueue = styled.span`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-family: 'Roboto', sans-serif;
    /* font-weight: none; */
    font-weight: ${props => props.bold && "bold"};
`

export const CustomImage = styled.img`
    width: 45px;
    height: 45px;
    padding-bottom: 20px;
`;

export const LogoImage = styled.img`
    width: 354.62px;
    height: 104.59px;
    padding-top: 40px;
`;

export const DivOffCameraCustomer = styled.div`
    background: black;
    border-radius: 1rem;
    margin: 20px 29% 0px 29%;
    height: -webkit-fill-available;
`;

export const GridTab = styled(Grid)`
    background-color: #F2F2F2;
`