import React, { Component } from 'react'
import { DivContainer, ImageBG, ImageDeviceNotAllowCameraAndMicrophone, DivContent, ButtonCustom, LogoCustom, ImgFingerCustom } from './style-component'
import { Link } from "react-router-dom";
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import ContentDeviceNotAllowCameraAndMicrophone from '../../assets/image/content-device-not-allow.png'
import ButtonActive from '../../assets/image/Button_Back_FirstPage.png'
import BGMobile from '../../assets/image/bg-mobile.png'


export default class DeviceNotAllowCameraAndMicrophone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 555
        }

    }

    componentDidMount() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
    }

    nextPage = () => {
    }


    render() {
        return (
            <React.Fragment>
                {/* <LogoCustom src={Logo} className="image-logo"/>
                <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                <DivContainer className='item-center'>
                    <ImageBG>
                        <ImageDeviceNotAllowCameraAndMicrophone src={ContentDeviceNotAllowCameraAndMicrophone} alt="thank you" className='zIdex-2'/>
                        <DivContent className='zIdex-2'>
                            <Link to={{
                                pathname: "/"
                            }}>
                                <ButtonCustom src={ButtonActive} />
                            </Link>
                        </DivContent>
                    </ImageBG>
                </DivContainer>
            </React.Fragment >


        )
    }
}