import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Peer from 'simple-peer';
import io from 'socket.io-client';
import share from '../../assets/Icons/share.svg'
import { Grid, Button, color } from '@material-ui/core';
import {
	GridButtonCall, GridScreenUser, LogoImage, GridQueue, TextCustom, SpanCustom, CustomImage, GridScreenOperator, GridButtonCallCustomer,
	TextCustomCustomer, SpanCustomQueue, DivOffCameraCustomer, DivLogout
} from './style-component'
import Services from '../../services/agentServices'
import Tip_Logo from '../../assets/image/Tip_Logo_Agent.svg'
import disableMic from '../../assets/image/Boutton_Mic_disable.svg'
import enableMic from '../../assets/image/Boutton_Mic_enable.svg'
import disableCamera from '../../assets/image/Button_Camera_disable.svg'
import enableCamera from '../../assets/image/Button_Camera_enable.svg'
import enableScreen from '../../assets/image/Button_Share Screen_enable.svg'
import disableScreen from '../../assets/image/Button_Share Screen_disable.svg'
// import ButtonCameraOff from '../../assets/image/Button_Camera.svg'
import HangUp from '../../assets/image/Hang_Up.svg'
// import ScreenKiosk from '../ScreenKiosk/'
// import { Howl } from 'howler'
import ringtone from '../../assets/ringtone/ringtone.mp3'
import Logout from '../../assets/Icons/logout.png'
import Online from '../../assets/Icons/online-icon.svg'
import Offline from '../../assets/Icons/offline-icon.svg'

// const ringtoneSound = new Howl({
// 	src: [ringtone],
// 	loop: true,
// 	preload: true
// })

var timeSetUp
var intervalSound


const Video = styled.video`
	height: 18rem;
	/* width:  40%; */
	
	margin-top: -20px;
	border-radius: 1rem !important;
	cursor: default;
	width: fit-content;
    align-self: center;
	-webkit-transform: scaleX(-1);
  	transform: scaleX(-1);
`;


const VideoShareScreen = styled.video`
	height: 100%;
	width:  40%;
	margin: 2rem;
	border-radius: 1rem;
`;

const Container = styled.div`
	/* margin: 1rem; */
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	/* justify-content: start;
	align-items: center;*/
	background-color: white; 
`;

const CallerContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const CallButton = styled.button`
	padding: 0.8rem;
	border-radius: 2rem;
	border-width: 0rem;
	cursor: pointer;

	&:hover {
		-webkit-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		transform: scaleX(1.05) scaleY(1.05);
		transform-origin: bottom;
	}

	&:focus {
		outline: none;
	}
`;

const AcceptButton = styled.button`
	margin: 1rem;
	padding: 0.8rem;
	border-radius: 2rem;
	border-width: 0rem;
	color: white;
	background-color: darkolivegreen;
	cursor: pointer;

	&:hover {
		-webkit-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		transform: scaleX(1.05) scaleY(1.05);
		transform-origin: bottom;
	}

	&:focus {
		outline: none;
	}
`;

const VideoContainer = styled.div`
	display: flex;
	justify-content: space-between;

	@media (max-width: 425px) {
		flex-direction: column;
	}
`;

const BlankContainer = styled.div`
	/* background-color: gray; */
	 display: flex;
	align-items: center;
	justify-content: center;
	color: #A2A6B9;
	border-radius: 1rem;
	font-size: 2rem;
	height: 20rem;
	width: 28rem;
	margin: 2rem;
	/* cursor: default;

	&:hover {
		-webkit-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		transform: scaleX(1.05) scaleY(1.05);
		transform-origin: bottom;
	} */
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const UsernameInput = styled.input`
	width: 15rem;
	height: 2rem;
	border-radius: 2rem;
	border-width: 0rem;
	margin: 1rem 0rem 0rem 0rem;
	padding: 0.25rem 0.75rem 0.25rem 0.75rem;
	font-size: 1.25rem;

	&:focus {
		-webkit-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		-moz-box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		box-shadow: 0px 0px 21px 15px rgba(0, 0, 0, 0.15);
		transform: scaleX(1.05) scaleY(1.05);
		transform-origin: bottom;
		outline: none;
	}
`;

const VideoShare = styled.video`
	/* height: -webkit-fill-available; */
	height: 90vh;
	width: 100%;
    margin: 0;
	/* position: absolute; */
`;



export function VideoCall() {
	const { state } = useLocation();
	let history = useHistory();
	const [count, setCount] = useState(0)
	const [screen, setScreen] = useState(null);
	const [stream, setStream] = useState(null);
	const [screenStream, setScreenStream] = useState(null);

	// const [username, setUsername] = useState(null);
	const [usernameForm, setUsernameForm] = useState("operator_" + state.id);
	// const [usernameForm, setUsernameForm] = useState("o");
	const [myID, setMyID] = useState();
	// const [onlineUsers, setOnlineUsersList] = useState({});
	const [callWithId, setCallWithId] = useState('');
	const [receivingCall, setReceivingCall] = useState(false);
	const [callerSignal, setCallerSignal] = useState();
	const [callerSignalScreen, setCallerSignalScreen] = useState();
	const [callAccepted, setCallAccepted] = useState(false);
	// const [, setConnectedPeer] = useState();
	const [uuidCustomer, setUuidCustomer] = useState('');
	const [nameCustomer, setNameCustomer] = useState('');
	const [phoneCustomer, setPhoneCustomer] = useState('');
	const [locationCustomer, setLocationCustomer] = useState('');
	const [emailCustomer, setEmailCustomer] = useState('');
	const [customerFrom, setCustomerFrom] = useState('');
	const [flagProcessCall, setFlagProcessCall] = useState(false);
	// const [isShareScreen, setIsShareScreen] = useState(false);
	const [muteAudio, setMuteAudio] = useState(false);
	const [hideVideo, setHideVideo] = useState(false);
	const [isScreenShare, setIsSchareScreen] = useState(false);
	const [displayCaller, setDisplayCaller] = useState({});

	const [gbAgentSts, setGbAgentSts] = useState('')
	const [stopperGetSts, setStopperGetSts] = useState(false)
	const [stopperCounterQueue, setStopperCounterQueue] = useState(false)
	const [showCallButton, setShowCallButton] = useState(true);
	const [shareFlag, setShareFlag] = useState(false);
	const [offCamemraFlag, setOffCamemraFlag] = useState(false);
	const [isKios, setIsKios] = useState(false);
	const [sharingScreenTo, setSharingScreenTo] = useState(false);
	const [isOnline, setIsOnline] = useState(false);
	const [watchingStatus, setWatchingStatus] = useState('break');
	// const [counterClean,setCounterClean] = useState(0);



	const socket = useRef();
	const userVideo = useRef();
	const partnerVideo = useRef();
	const sharingScreen = useRef();
	// const sharingScreenTo = useRef();
	const myPeerScreen = useRef();
	const myPeer = useRef();



	// Use for cleaning up
	useBeforeunload(hangUp);

	/////////

	const [seconds, setSeconds] = useState(0);
	const [isActive, setIsActive] = useState(false);

	const [secondsEnd, setSecondsEnd] = useState(0);
	const [isActiveEnd, setIsActiveEnd] = useState(false);
	const [isCloseToEnd, setIsCloseToEnd] = useState(false);
	const [messageClose, setMessageClose] = useState("");

	function timerToggle() {
		setIsActive(!isActive);
	}

	function timerToggleEnd() {
		setIsActiveEnd(!isActiveEnd);
	}

	function timerReset() {
		if (uuidCustomer) {
			Services.operator_endCall_HangUp({ "uuid": uuidCustomer }).then((res) => {
				console.log('agent say end call.')
			})
		}
		setSeconds(0);
		setIsActive(false);
		setSecondsEnd(0)
		setIsActiveEnd(false);
	}

	useEffect(() => {
		let interval = null;
		if (isActive) {
			interval = setInterval(() => {
				setSeconds((seconds) => seconds + 1);
			}, 1000);
		} else if (!isActive && seconds !== 0) {
			clearInterval(interval);
		}
		return () => clearInterval(interval);
	}, [isActive, seconds]);

	useEffect(() => {
		let interval = null;

		if (isActiveEnd) {
			interval = setInterval(() => {
				setSecondsEnd((seconds) => seconds + 1);
				// const format = moment(secondsEnd).format("ss");
				// setTimeSeconds(format)

			}, 1000);
		} else if (!isActiveEnd && secondsEnd !== 0) {
			clearInterval(interval);
		}
		console.log("secondsEnd", secondsEnd);

		let inTime = 60
		inTime = inTime - secondsEnd
		// if (!callAccepted && Object.values(onlineUsers).length > 1) {
		// 	setMessageClose(`Operator cannot accept call... ${inTime}`)
		// } else {
		setMessageClose(`Time left ${inTime} second${inTime <= 1 ? '' : 's'} `)
		// }

		if (inTime == 0) {
			setIsCloseToEnd(false)
			ClearingHangup()
		}

		return () => clearInterval(interval);

	}, [isActiveEnd, secondsEnd]);

	//////////

	useEffect(() => {
		socket.current = io.connect('/',{
			query: {
				token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRpcHVjbWUifQ.V3p3AegTVWzRMfD0Y2gsDPnZk-KRh7bTylowMvR6L0g'
			}
		});
		socket.current.on("connect_error", (err) => {
			console.log(err instanceof Error); // true
			console.log(err.message); // not authorized
			console.log(err.data); // { content: "Please retry later" }
		});
		navigator.mediaDevices
			.getUserMedia({
				video: {
					"mandatory": {
						"minWidth": 120,
						"maxWidth": 180,
						"minHeight": 250,
						"maxHeight": 400,
						"minFrameRate": 30
					}
				}, audio: true
			})
			.then((stream) => {
				setStream(stream);
				if (userVideo.current) {
					userVideo.current.srcObject = stream;
				}
				// screen
				// navigator.mediaDevices.getDisplayMedia({
				// 	video: true
				// }).then(screen => {
				// 	setScreenStream(screen);
				// 	if (sharingScreen.current) {
				// 		sharingScreen.current.srcObject = screen;
				// 		// setIsShareScreen(true)
				// 	}
				// 	// hideVideo();
				// })
			});
		socket.current.on('id_report', (id) => {
			setMyID(id);
		});
		socket.current.on('client_screen_flag', (data) => {
			setShareFlag(data.flag);
			console.log("set flag", data);
			// screenShare
		});
		// socket.current.on('client_off_flag', (data) => {
		// 	setOffCamemraFlag(data.flag);
		// 	console.log("set flag on/off camera", data);
		// });

		// socket.current.on('username_is_set', ({ _username }) => {
		// 	setUsername(_username);
		// });
		// socket.current.on('online_users_report', (users) => {
		// 	setOnlineUsersList(users);
		// });

		socket.current.on('connect', function () {
			console.log('agent is connected kub.', socket.current.connected);
			socket.current.emit('set_username', {
				username: usernameForm,
			});
		});
		// socket.current.on("disconnect", (reason) => {
		// 	console.log(socket.current.connected,reason)
		// 	console.log('count down to re-connect.')
		// 	setTimeout(()=>{
		// 		socket.current.connect();
		// 		socket.current.emit('set_username', {
		// 			username: usernameForm,
		// 		});
		// 		console.log('trigger re-connect.')
		// 		console.log(socket.current.connected,reason)
		// 	},5000)
		// });
		socket.current.on('someone_calling', (data) => {
			console.log("data someone_calling 555 >> ", data)
			// ringtoneSound.play();
			playSound(true)
			setDisplayCaller(data)
			setReceivingCall(true);
			setCallWithId(data.from);
			setCallerSignalScreen(data.signalScreen);
			setCallerSignal(data.signal);
			setSecondsEnd(0)
			setIsActiveEnd(false);
			timerToggleEnd();
			// console.log('===============>',callWithId,callerSignal)
			// setTimeout(()=>{
			// 	console.log('auto accpet call.')
			// 	acceptCall()
			// },3000)
		});
		console.log("callerSignalScreen > ", callerSignalScreen)

		socket.current.emit('set_username', {
			username: usernameForm,
		});

		// Services.operator_setOnline({ "status": "online" }).then((res) => {
		// 	console.log("operator_setOnline", res.data);
		// })


		let intervalCallCheck = setInterval(() => {
			// console.log(timeSetUp)
			// get queue customer agent side
			if (!stopperCounterQueue) {
				setStopperCounterQueue(true)
				Services.operator_getCustomerOnWaitingCall().then((res) => {
					setCount(res.data.waiting_queue)
					setStopperCounterQueue(false)
				})
			}
			if (!stopperGetSts) {
				setStopperGetSts(true)
				Services.opreator_getOperationStatus().then((res) => {
					console.log(res.data.opreator.operator_status)
					if (res.data.opreator.operator_status == 'online') {
						setIsOnline(true)
						setWatchingStatus('online')
						console.log("opreator_getOperationStatus");
						// setIsOnline(true)
						// setWatchingStatus('online')
						console.log(timeSetUp)
						if (!timeSetUp) {

							console.log('status online set interval action to busy')
							timeSetUp = setInterval(intervalCallBack, 2000)
						}
					}
					//
					Services.operator_getMyCustomerOnWaitingCall({ "status": "getMyCustomer" }).then((res2) => {
						if (typeof res2.data.customer_focus != 'undefined') {
							if (res2.data.customer_focus.customer_end_call) {
								ClearingHangup()
								clearInterval(intervalCallCheck)
								return
							}
							// handleChangeStutus()
							console.log("operator_getMyCustomerOnWaitingCall");
							setIsOnline(false)
							setWatchingStatus('busy')
							console.log(res2.data.customer_focus);
							setUuidCustomer(res2.data.customer_focus.uuid)
							setNameCustomer(res2.data.customer_focus.first_name ? res2.data.customer_focus.first_name + ' ' + res2.data.customer_focus.last_name : "")
							setPhoneCustomer(res2.data.customer_focus.phone && res2.data.customer_focus.phone)
							setLocationCustomer(res2.data.customer_focus.private_ip)
							// setShareFlag(res2.data.customer_focus.first_name != "kios" ? false : true)
						}

					})

					//
					// setIsOnline(false)
					// setWatchingStatus('break')
					setGbAgentSts(res.data.opreator.operator_status);
					setIsCloseToEnd(true)
					setStopperGetSts(false)
				})
			}
		}, 2000)
	}, []);

	function intervalCallBack() {
		Services.opreator_getOperationStatus().then((res) => {
			// setGbAgentSts(res.data.opreator.operator_status);
			// console.log("opreator_getOperationStatus", res.data, res.data.operator_status == "online");
			console.log('interval callBack sts', res.data.opreator.operator_status)
			if (res.data.opreator.operator_status == "online") {
				// console.log('3333 .>>>>>>>>>>>>>>>>>>>>>>>>', res.data)
				//set busy to api for finding customer
				Services.operator_setBusy({
					"status": "busy"
				}).then((res2) => {

					console.log('try to set busy >>>>>>>>>>>>', res2.data.message)
					if (res2.data.message != 'no action.') {
						clearInterval(timeSetUp)
						timeSetUp = false
						// setGbAgentSts('busy');
						if (typeof res2.data.customer_focus.uuid != 'undefined') {

							setUuidCustomer(res2.data.customer_focus.uuid)
							setNameCustomer(res2.data.customer_focus.first_name ? res2.data.customer_focus.first_name + ' ' + res2.data.customer_focus.last_name : "")
							setPhoneCustomer(res2.data.customer_focus.phone && res2.data.customer_focus.phone)
							setLocationCustomer(res2.data.customer_focus.private_ip)
							setEmailCustomer(res2.data.customer_focus.email && res2.data.customer_focus.email)
							setCustomerFrom(res2.data.customer_focus.private_ip ? res2.data.customer_focus.private_ip : null)
							timerToggleEnd()

						}
						// console.log(res2.data);
						if (typeof res2.data.customer_focus.uuid != 'undefined') {
							// Services.operator_getMyCustomerOnWaitingCall()
							let obj2 = {
								"uuid": res2.data.customer_focus.uuid
							}
							// Services.operator_getMyCustomerOnWaitingCall({ "status": "getMyCustomer" }).then((res2) => { })
							Services.operator_startCall_Calling(obj2).then((res3) => {
								// acceptCall()
								console.log('call operator start call')

							})

						}
					}
				})
			} else if (res.data.opreator.operator_status == "busy") {
				console.log('for now busy please cancel queue now.')
				setIsOnline(false)
				clearInterval(timeSetUp)
				timeSetUp = false
			} else {
				//
				//	status => break,offline
				//
				setIsOnline(false)
				clearInterval(timeSetUp)
				timeSetUp = false
			}
			// else if(res.data.opreator.operator_status == "busy"){
			// 	console.log('is busy')
			// }
		})
	}

	// useEffect(() => {
	// 	if (callerSignal && receivingCall && !callAccepted && callWithId && !flagProcessCall) {
	// 		console.log('use effect auto accept call')
	// 		acceptCall()
	// 	}
	// }, [callerSignal]);

	function acceptCall() {
		console.log('acceptCall++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++')
		// ringtoneSound.unload();
		playSound(false)
		setSecondsEnd(0)
		setIsActiveEnd(false);
		setIsCloseToEnd(false)
		setFlagProcessCall(true)
		setShowCallButton(false)
		timerToggle();
		setCallAccepted(true);
		setWatchingStatus('busy')
		setReceivingCall(false);
		setShareFlag(nameCustomer == "kios test" ? true : false)
		setIsSchareScreen(false);
		socket.current.emit('toggle_screen', {
			callId: callWithId, // destination
			flag: false
		});

		myPeer.current = new Peer({
			initiator: false,
			trickle: false,
			stream: stream,
			config: {
				iceServers: [
					// deprecated { urls: "turn:178.128.95.129:3478", username: "eibiz", credential: "turn1234" }
					// { urls: "turn:3.1.84.112:3478", username: "eibiz", credential: "turn1234" }
					{ urls: "turn:159.138.237.156:3478", username: "eibiz", credential: "turn1234" }
					//{ urls: "turn:numb.viagenie.ca", username: "qwaszx.za@gmail.com", credential: "151010105" },{ urls: "stun:numb.viagenie.ca" }
					//,{ urls: "stun:numb.viagenie.ca" },{ urls: "turn:numb.viagenie.ca", username: "muazkh", credential: "webrtc@live.com" }
				],
				sdpSemantics: 'unified-plan'
			}
		});

		myPeerScreen.current = new Peer({
			initiator: false,
			trickle: false,
			stream: stream,
			config: {
				iceServers: [
					// deprecated { urls: "turn:178.128.95.129:3478", username: "eibiz", credential: "turn1234" }
					// { urls: "turn:3.1.84.112:3478", username: "eibiz", credential: "turn1234" }
					{ urls: "turn:159.138.237.156:3478", username: "eibiz", credential: "turn1234" }
					//{ urls: "turn:numb.viagenie.ca", username: "qwaszx.za@gmail.com", credential: "151010105" },{ urls: "stun:numb.viagenie.ca" }
					//,{ urls: "stun:numb.viagenie.ca" },{ urls: "turn:numb.viagenie.ca", username: "muazkh", credential: "webrtc@live.com" }
				],
				sdpSemantics: 'unified-plan'
			}
		});

		let call_accept_calling = {
			signal: '',
			signalScreen: '',
			to: callWithId,
		}

		console.log("call_accept_calling >> ", call_accept_calling)
		myPeer.current.on('signal', (data) => {
			console.log('signal peer', data.type)
			// setTimeout(()=>{
			call_accept_calling['signal'] = window.btoa(JSON.stringify(data))
			setIsCloseToEnd(true)
			// },500)

			// socket.current.emit('accept_calling', call_accept_calling);
		});

		myPeerScreen.current.on('signal', (data222) => {
			console.log('signal peer222', data222.type)
			call_accept_calling['signalScreen'] = window.btoa(JSON.stringify(data222))
			let signal_interval = setInterval(() => {
				console.log(call_accept_calling)
				if (call_accept_calling['signal'] != '') {
					// setTimeout(()=>{
					socket.current.emit('accept_calling', call_accept_calling);
					console.log('accept calling')
					// },5000)
					setShareFlag(nameCustomer == "kios test" ? true : false)
					clearInterval(signal_interval)
				} else {
					console.log('waiting signal')
				}
			}, 100)
		});
		// console.log("call_accept_calling >> ", call_accept_calling)

		myPeer.current.on('data', data => {
			// got a data channel message
			console.log('got a message from peer1: ' + data)
		})

		myPeerScreen.current.on('data', data => {
			// got a data channel message
			console.log('got a message from peer1: ' + data)
		})

		console.log("callerSignal >", callerSignal.type)
		console.log("callerSignalScreen >", callerSignalScreen.type)

		myPeerScreen.current.signal(callerSignalScreen)
		myPeer.current.signal(callerSignal);


		myPeer.current.on('stream', (stream) => {
			partnerVideo.current.srcObject = stream;
		});

		myPeerScreen.current.on('stream', (item) => {
			console.log('pass myPeerScreen')
			if (sharingScreen.current) {
				sharingScreen.current.srcObject = item;
				setSharingScreenTo(sharingScreen)
			}
		});


		socket.current.emit('call_initiate', {
			callId: callWithId,
			callerId: myID,
		});

		socket.current.on('hang_up', () => {
			// alert('hangup by customer')
			console.log("Hang up socket from customeer")
			ClearingHangup()
		});
		console.log("call_accept_calling > ", call_accept_calling)

		// if (usernameForm) {
		// 	const urlParams = new URLSearchParams(window.location.search);
		// 	let screenParam = urlParams.get('screen-kios')
		// 	console.log("get param screenParam");
		// 	if (screenParam) {
		// 		setIsKios(true)
		// 	}
		// }
	}

	function playSound(isPlay) {
		// let src = '/media/notification.ogg';
		let audio = new Audio(ringtone);
		if (isPlay) {
			audio.play();
			// if(secondsEnd > 60){
			intervalSound = setInterval(() => {
				audio.play();
			}, 1500);	
			
			
			// }
			// setInterval(function(){audio.pause();  }, 1500);
		} else {
			clearInterval(intervalSound)
			audio.pause();

			// audio.stop()
		}



	}


	function hangUp() {
		try {
			// alert('hangup by agent')
			socket.current.removeListener('hang_up')
			socket.current.emit('end_call', {
				id: myID,
				elapseTime: seconds,
			});


			socket.current.emit('toggle_screen', {
				callId: callWithId, // destination
				flag: false
			});
			ClearingHangup()
		} catch (e) { }
	}

	function handleLogout() {
		history.push('/login')
		ClearingHangup()
	}

	function ClearingHangup() {

		PartnerVideo = null;
		clearInterval(intervalSound)
		intervalSound = null
		// partnerVideo.current.srcObject = "";
		if (muteAudio) {
			changeOptionsAudio();
		}
		if (hideVideo) {
			changeOptionsVideo();
		}
		// ringtoneSound.unload();
		setSecondsEnd(0)
		setIsActiveEnd(false)
		setMessageClose("")
		setShareFlag(false)
		setIsKios(false)
		setShowCallButton(true)
		setHideVideo(false);
		setMuteAudio(false);
		setCallWithId('');
		setReceivingCall(false);
		setCallerSignal(null);
		setCallerSignalScreen(null);
		setCallAccepted(false);
		// setConnectedPeer(null);
		setFlagProcessCall(false);
		setIsSchareScreen(false)
		stopShare();
		timerReset();
		console.log("clear all");
		setIsSchareScreen(false)
		myPeer.current = null
		myPeerScreen.current = null
		setTimeout(() => {
			Services.operator_setOnline({ "status": "break_hangup" }).then((res) => {
				console.log(res.data)
				console.log("break status");
				setWatchingStatus('break')
				setGbAgentSts('break');
				setIsOnline(false)
			})
			window.location.reload()
		}, 500)
		// myPeer.current.reset()
		// myPeerScreen.current.reset()
		// if(counterClean>=3){
		// 	setCounterClean(0);
		// 	window.location.reload()
		// }else{
		// 	setCounterClean(counterClean+1);
		// }
	}

	// function EndQueuNow() {
	// 	if (uuidCustomer) {
	// 		Services.operator_endCall_HangUp({
	// 			"uuid": uuidCustomer
	// 		}).then((res) => {
	// 			setUuidCustomer("")
	// 			setGbAgentSts('break');
	// 		})
	// 	} else {
	// 		Services.operator_setOnline({ "status": "break" }).then((res) => {
	// 			console.log(res.data)
	// 			setGbAgentSts('break');
	// 		})

	// 	}


	// }

	// function SetReadyToCall() {
	// 	Services.operator_setOnline({ "status": "online" }).then((res2) => {
	// 		console.log(res2.data)
	// 	})
	// }

	let UserVideo;
	if (stream) {
		UserVideo = <Video id="UserVideo" playsInline muted ref={userVideo} autoPlay />;
	}

	let PartnerVideo;
	if (callAccepted) {
		PartnerVideo = <Video id="PartnerVideo" playsInline ref={partnerVideo} autoPlay />;
		// PartnerVideo =
		// 	<>
		// 		<Video playsInline ref={partnerVideo} autoPlay style={{ display: offCamemraFlag ? "none" : "grid" }} />
		// 		<DivOffCameraCustomer style={{ display: offCamemraFlag ? "grid" : "none" }} ></DivOffCameraCustomer>
		// 	</>

	}

	function stopShare() {
		try {
			if (screenStream) {
				var tracks = screenStream.getTracks();
				for (var i = 0; i < tracks.length; i++) tracks[i].stop();
			}
			// if (isScreenShare) {
			// 	// sharingScreen.current = null
			// 	setIsSchareScreen(false)
			// 	setShareFlag(false)
			// 	socket.current.emit('toggle_screen', {
			// 		callId: callWithId, // destination
			// 		flag: false
			// 	});
			// }
			if (isScreenShare) {
				console.log('isScreenShare', isScreenShare)
				navigator.mediaDevices.getUserMedia({
					video: {
						"mandatory": {
							"minWidth": 120,
							"maxWidth": 180,
							"minHeight": 250,
							"maxHeight": 400,
							"minFrameRate": 30
						}
						// "mandatory": {
						// 	"maxWidth": 180,
						// 	"maxHeight": 400,
						// }
					}, audio: true
				}).then(screen => {
					console.log('screen 869')
					setStream(stream);
					if (sharingScreen.current) {
						sharingScreen.current.srcObject = screen;
						// 	// setIsShareScreen(true)
					}
					// change peer streaming
					myPeerScreen.current.replaceTrack(stream.getVideoTracks()[0], screen.getVideoTracks()[0], stream)
					setIsSchareScreen(false);
					setShareFlag(false)
					socket.current.emit('toggle_screen', {
						callId: callWithId, // destination
						flag: false
					});
				})
			}
		} catch (error) {
			console.log('error', error)
			stopShare();
		}
	}


	function shareScreen() {
		console.log("shareScreen - " + isScreenShare)
		if (isScreenShare) {
			stopShare();
		} else {
			navigator.mediaDevices.getDisplayMedia({
				video: {
					"mandatory": {
						// "maxWidth": 1600,
						// "maxHeight": 1200,
						width: { ideal: 4096 },
						height: { ideal: 2160 } 
					}
				},
			}).then(screen => {
				setScreenStream(screen);
				setShareFlag(true)
				if (sharingScreen.current) {
					sharingScreen.current.srcObject = screen;
					// 	// setIsShareScreen(true)
				}
				// change peer streaming
				myPeerScreen.current.replaceTrack(stream.getVideoTracks()[0], screen.getVideoTracks()[0], stream)

				setIsSchareScreen(true);
				socket.current.emit('toggle_screen', {
					callId: callWithId, // destination
					flag: !isScreenShare
				});

				// screen.getTracks()[0].onended = () => {
				// 	console.log('screen share ended.')
				// 	setIsSchareScreen(false);
				// 	socket.current.emit('toggle_screen', {
				// 		callId: callWithId, // destination
				// 		flag: false
				// 	});
				// }
				// hideVideo();
			})
		}
	}

	function handleChangeStutus() {
		console.log('handleChangeStutus');
		if (isOnline) {
			console.log('if');
			Services.operator_setBusy({
				"status": "break"
			}).then((res2) => {
				if (res2.data.message == 'no action.') {
					// setIsOnline(false)
					// setWatchingStatus('break')
					setWatchingStatus('break')
					setGbAgentSts('break');
					setIsOnline(false)
				}

			})
		} else {
			console.log('else');
			Services.operator_setOnline({
				"status": "online"
			}).then((res2) => {
				if (res2.data.message == 'no action.') {
					setIsOnline(true)
					setWatchingStatus('online')
				}

			})
		}
		// if(watchingStatus=='break'){
		// 	Services.operator_setOnline({
		// 		"status": "online"
		// 	}).then((res2) => {
		// 		if (res2.data.message == 'no action.') {
		// 			setIsOnline(true)
		// 			setWatchingStatus('online')
		// 		}

		// 	})
		// }
	}

	function changeOptionsAudio() {
		if (stream) {
			stream.getAudioTracks()[0].enabled = muteAudio
			setMuteAudio(!muteAudio)
		}
	}

	function changeOptionsVideo() {
		if (stream) {
			stream.getVideoTracks()[0].enabled = hideVideo
			setHideVideo(!hideVideo)
		}

	}
	function changeOptionsScreen() {
		console.log("Share screen")
		shareScreen();
	}

	let ShareScreenVideo;
	if (sharingScreen) {
		ShareScreenVideo = (
			<VideoShare className="sharingScreen" playsInline muted ref={sharingScreen} autoPlay />
		);
	}

	return (

		<>

			{/* <div style={{ display: "none" }}>
				<ScreenKiosk sharingScreen={sharingScreen} />
			</div> */}
			<Container style={{ display: isKios ? "none" : "flex" }}>

				<div className='flex-1 h-100 o-auto'>
					<Grid container className="h-100 o-auto">
						<Grid item xs={4} sm={4} md={4} xl={4} className="h-100">
							<Grid container className="h-100 pt-3">
								<GridButtonCall item xs={1} sm={1} md={1} xl={1} className='text-center pt-3' container direction='column' justifyContent='flex-start' alignItems='center'>
									<CustomImage src={muteAudio ? disableMic : enableMic} onClick={changeOptionsAudio} className='pt-15' />
									<CustomImage src={hideVideo ? enableCamera : disableCamera} onClick={changeOptionsVideo} />
									{flagProcessCall ?
										nameCustomer != "kios test" && <CustomImage src={flagProcessCall && isScreenShare ? disableScreen : enableScreen} onClick={changeOptionsScreen} /> :
										''}
								</GridButtonCall>
								<GridScreenOperator item xs={11} sm={11} md={11} xl={11} className='text-center d-flex j-center pt-3'>
									{/* {ShareScreenVideo ? (
										ShareScreenVideo
									) : null} */}

									{UserVideo}
									<div className='mt-10'>
										<TextCustom color='#293252' size='14px' bold>{(usernameForm).toString().replace('operator_','Operator ')}</TextCustom>
									</div>

								</GridScreenOperator>
								<GridButtonCallCustomer item xs={1} sm={1} md={1} xl={1} className='pt-3' container direction='column' justifyContent='flex-start' alignItems='center'>
									{callAccepted && callWithId && <img src={HangUp} className='pt-15' onClick={hangUp} />}
									<DivLogout>
										<TextCustom color='#FFFFFF'>{gbAgentSts}</TextCustom>
										<img src={isOnline ? Online : Offline} onClick={handleChangeStutus} />
										<img src={Logout} className="pointer" onClick={handleLogout} />
									</DivLogout>

								</GridButtonCallCustomer>
								<GridScreenUser item xs={11} sm={11} md={11} xl={11} className='text-center d-flex j-center pt-3' >

									{PartnerVideo ? (
										<React.Fragment>
											{
												PartnerVideo
											}
											<Grid container rowSpacing={2} columnSpacing={2} justifyContent='flex-start' alignItems='flex-start'>
												<Grid item xs={4} />
												<Grid item xs={2}>
													<TextCustomCustomer color='#293252' size='14px' bold>Name</TextCustomCustomer>
												</Grid>
												<Grid item xs={6}><SpanCustom color='#A2A6B9' size='14px' >{" " + nameCustomer}</SpanCustom></Grid>
												<Grid item xs={4} />
												<Grid item xs={2}><TextCustomCustomer color='#293252' size='14px' bold>Tel.</TextCustomCustomer></Grid>
												<Grid item xs={6}><SpanCustom color='#A2A6B9' size='14px' >{" " + phoneCustomer}</SpanCustom></Grid>
												<Grid item xs={4} />
												{ locationCustomer && (
													<>
														<Grid item xs={2}><TextCustomCustomer color='#293252' size='14px' bold>Location.</TextCustomCustomer></Grid>
														<Grid item xs={6}><SpanCustom color='#A2A6B9' size='14px' >{" " + locationCustomer}</SpanCustom></Grid>
														<Grid item xs={4} />
													</>
												)}
												<Grid item xs={2}><TextCustomCustomer color='#293252' size='14px' bold>Call from</TextCustomCustomer></Grid>
												<Grid item xs={6}><SpanCustom color='#A2A6B9' size='14px' >{nameCustomer == "kios test" ? " KIOSK" : " Mobile"}</SpanCustom></Grid>

											</Grid>
										</React.Fragment>
									) : (
										<BlankContainer className='align-self-center text-center'>
											{
												displayCaller.from && receivingCall && !callAccepted && callWithId && showCallButton
													? <div style={{ display: "grid", justifyItems: "center" }}>

														{nameCustomer != "kios test" ?
															<img onClick={acceptCall} src={"https://du.lnwfile.com/_/du/_fit/300/300/7v/x4/tn.png"} width={'100px'} className='pt-10' />
															:
															<img onClick={acceptCall} src={"https://du.lnwfile.com/_/du/_fit/300/300/7v/x4/tn.png"} width={'100px'} className='pt-10' />}
														{/* {isCloseToEnd && <p >{messageClose}</p>} */}
													</div>

													:

													gbAgentSts == 'busy' ?
														<div style={{ display: "grid", justifyItems: "center" }}>
															<p>Queue Matching waiting for start call</p>
															<br />
															{/* {isCloseToEnd && <p>{messageClose}</p>} */}
														</div>
														:
														gbAgentSts == 'break' ?
															<>
																{'Waiting for Customer.'}
															</>
															:
															<>
																<p>{'Ready To Call.'}</p>
															</>



											}
											{shareFlag}
										</BlankContainer>
									)}

								</GridScreenUser>
								<GridQueue item xs={12} sm={12} md={12} xl={12} className='pt-3'>
									<div className='as-center text-center'>

										<TextCustom color='#FFFFFF' size='20px' bold={false}>The number of people waiting
											<SpanCustomQueue className='pl-10' color='#293252' size='20px' bold={true}>{' ' + count + ' people'}</SpanCustomQueue>
										</TextCustom>
									</div>
								</GridQueue>

								<Grid item xs={6} sm={6} md={6} xl={6} >

								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={8} sm={8} md={8} xl={8} className="h-100" style={{ display: shareFlag ? "flex" : "none", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
							{
								ShareScreenVideo ? (
									ShareScreenVideo
								) : <>
									<TextCustomCustomer color='#293252' size='20px'>Customer Service</TextCustomCustomer>
									<TextCustomCustomer color='#293252' size='40px'>Live Assistant</TextCustomCustomer>
									<LogoImage src={Tip_Logo} />
								</>
							}
						</Grid>
						<Grid item xs={8} sm={8} md={8} xl={8} className="h-100" style={{ display: shareFlag ? "none" : "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
							{/* <div> */}
							<TextCustomCustomer color='#293252' size='20px'>Customer Service</TextCustomCustomer>
							<TextCustomCustomer color='#293252' size='40px'>Live Assistant</TextCustomCustomer>
							<LogoImage src={Tip_Logo} />
							{/* </div> */}
						</Grid>
					</Grid>
				</div>
			</Container>
		</>
	);
}

export default VideoCall;


// // HeaderComponent
// export function ScreenKiosk({ sharingScreen }) {
// 	return (
// 		<div style={{ width: "500px", height: "500px", backgroundColor: "white" }}>
// 			<video playsInline muted value={sharingScreen} autoPlay />
// 		</div>
// 	);
// }
