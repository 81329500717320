import styled from 'styled-components';
import { TextField, Grid, Radio, RadioGroup } from '@material-ui/core';

export const TextHeader = styled.p`
    font-size: 40px;
`;

export const SubText = styled.span`
    font-size: 40px;
    color: white;
    text-shadow: none;
`;


export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20vh;
`;

export const DivImageHeader = styled.div`
    padding-top: 20px;
    text-align: center;
    @media screen and (max-width: 430px) {
        padding-top: 0;
    }
`


export const ImageHeader = styled.img`
    margin-bottom: 22px;
    width: 36vw;
    max-width: 300px;
    @media screen and (max-width: 430px) {
        margin-top: 4vh;
        width: 60vw;
    }
`

export const DivContentAds = styled.div`
   flex : 1;
   text-align: center;
   padding-top: 8%;
    padding-bottom: 10%;
   z-index: 2;
   /* @media screen and (min-width: 420px) {
    padding-top: 40%;
   } */
   @media screen and (max-width: 350px) and (min-width: 300px) {
        /* width: 100px; */
        padding-top: 0%;
    }
`;

export const DivContent = styled.div`
    text-align: center;
    position: absolute;
    bottom: -28px;
    width: 100%;
    z-index: 3;
`;

export const SpecialText = styled.p`
    font-size: 50px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: red;
`

export const LogoCustom = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 13vh;
    padding: 5vw;
`

export const ImgFingerCustom = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 13vh;
    max-width: 45vw;
`

export const DivLogo = styled.div`
    padding-top: 3%;
    padding-right: 8%;
    display: flex;
    align-self: flex-end;
`

export const TextFieldinput = styled(TextField)`
    input {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 3px;
        /* font-size: 1.1em;  */ 
        background-color : #FFFFFF;
        border-radius: 5px;
        
        color : #2b2b2b;
    }
    fieldset {
        border-radius: 5px;
    }
`;

export const GridCustom = styled(Grid)`
    text-align: center;
`;

export const SpanCustom = styled.span`
    font-family: "HB Heavent";
    font-size: 20px;
    line-height: 0.7;
`

export const ButtomNextDisabled = styled.img`
    /* height: 29px; */
    margin-top: -4px;
`

export const ButtomActive = styled.img`
    /* height: 29px; */
     margin-top: -4px;
`

export const DivContentCondition = styled.div`
    height: 37.5vh;
    background-color: white;
    color: #707070;
    margin-left: 2rem;
    margin-right: 2rem;
    border-radius: 1rem;
    @media screen and (max-width: 430px) {
        margin-left: 1rem;
        margin-right: 1rem;
        height: 33.5vh;
    }
`;

export const RadioCustom = styled(RadioGroup)`
    .PrivateSwitchBase-root-1 {
        /* padding: 9px; */
        /* padding-bottom: 65px !important; */
        
    }
    
`

export const ImgRadio = styled.img`
    margin-top: -7px;
    width: 20px;
    height: 20px;
`

export const ButtonBackCustom = styled.img`
    width: 104px;
    height: 41px;
    padding-left: 40px;
    @media screen and (max-width: 350px) and (min-width: 300px) {
        padding-left: 10px;
    }
`

export const SVCButtonCustom = styled.img`
    max-width: 196px;
    width: 28vw;
    min-width: 178px;
`

export const DivRadio = styled.div`
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 10px;
    @media screen and (max-width: 430px) {
        margin-left: 1rem;
        margin-right: 1rem;
    }
`

export const FormInput = styled.div`

`
export const ImageBG = styled.div`
    max-width: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    width: 62vw;
    min-height: 57vh;
    height: fit-content;
    color: #ffffff;
    z-index: 1;
    background-color: #4c6dff75;
    border-radius: 24px;
    box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    @media screen and (max-width: 430px) {
        width: 90vw;
    }
`