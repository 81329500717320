import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { DivContainer, DivContent, ButtonCustom, ImageThanks, ImageBG, LogoCustom, ImgFingerCustom } from './style-component'
import ContentThanks from '../../assets/image/content-thank-4.png'
import ButtonActive from '../../assets/image/Button_Back_FirstPage.png'
import Services from '../../services/agentServices'
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import BGMobile from '../../assets/image/bg-mobile.png'

export default class Thanks extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 555
        }

    }

    componentDidMount() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
        const urlParams = new URLSearchParams(window.location.search);
        let tokenParam = urlParams.get('token')
        if (tokenParam) {
            console.log('push to video call agent');
            localStorage.setItem("token", tokenParam)
            Services.opreator_getOperationStatus().then((res) => {
                console.log(res.data);
                this.props.history.push({
                    pathname: "/agent-videocall",
                    state: res.data.opreator
                });
            })
            // console.log(tokenParam);

        } else {
            // console.log('do nothing'); 
        }
    }

    nextPage = () => {

        // history.push('/form');
        // this.props.history.push('/form');
    }


    render() {
        return (
            <React.Fragment>
                {/* <LogoCustom src={Logo} className="image-logo" />
                <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                <DivContainer className='item-center'>
                    <ImageBG>
                        <ImageThanks src={ContentThanks} alt="thank you" className='zIdex-2'/>
                        <DivContent className='zIdex-2'>
                            <Link to={{
                                pathname: "/"
                            }}>
                                <ButtonCustom src={ButtonActive} />
                            </Link>
                        </DivContent>
                    </ImageBG>
                </DivContainer>
            </React.Fragment >


        )
    }
}