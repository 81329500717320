import styled from 'styled-components';
import ButtonActive from '../../assets/image/Button_Next_Active.png'

export const TextHeader = styled.p`
    font-size: 40px;
`;

export const SubText = styled.span`
    font-size: 40px;
    color: white;
    text-shadow: none;
`;


export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20vh;
    width: 90vw;
    margin: auto;
`;

export const DivContentAds = styled.div`
   position: relative;
   justify-content: center;
   display: flex;
`;
export const DivContent = styled.div`
    flex : 1;
    text-align: center;
    position: absolute;
    bottom: -3.5vh;
    width: 100%;
    z-index: 3;
`;

export const SpecialText = styled.p`
    font-size: 50px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: red;
`

export const ButtonNext = styled.img`
    img :hover {
        background-image: ButtonActive;
    }
`

export const ButtonCustom = styled.img`
    width: 19vw;
    min-width: 120px;
    max-width: 139px;
`

export const DivLogo = styled.div`
    padding-top: 3%;
    padding-right: 8%;
    display: flex;
    align-self: flex-end;
`

export const ImageAds = styled.img`
    width: 300px;
`

export const DivCenter = styled.div`
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    body{
        background-image: none !important;
        //background-image: url("./assets/image/background.png");
    }
    
`;


export const DivTest = styled.div`
           height: 200vh;
        width: 100%;
        position: absolute;
        background-color: #043183;
        //background-image: url("./assets/image/background.png");
`;

export const DivReletive = styled.div`
    background-image: url("../../assets/new/BG.svg");
    position: relative;
`

export const ImageBG = styled.img`
    z-index: 1;
`

export const LogoCustom = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 13vh;
    padding: 20px;
`

export const ImgFingerCustom = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 13vh;
    max-width: 45vw;
`

export const ImageAdsContent = styled.img`
    max-width: 400px;
    z-index: 2;
    // height: 100%;
    width: 100%;
    background-color: #4c6dff75;
    border-radius: 24px;
    box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    @media screen and (max-width: 430px) {
        // width: 90vw;
    }
`