import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    DivContainer, DivContent, DivContentCondition, RadioCustom, SpanCustom, ImgRadio,
    SVCButtonCustom, DivImageHeader, DivRadio, ImageBG, FormInput, ImageHeader, LogoCustom, ImgFingerCustom
} from './style-component'
import ConditionsHeader from '../../assets/image/Conditions.svg'
import ButtonNextActive from '../../assets/image/Button_SVC_Active.png'
import ButtonNext from '../../assets/image/Button_SVC.png'
import Checked from '../../assets/image/Checked.png'
import Unchecked from '../../assets/image/Unchecked.png'
import { FormControlLabel, Radio } from '@material-ui/core';
import { v4 as uuidv4 } from "uuid";
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import BGMobile from '../../assets/image/bg-mobile.png'

export default class TermConditions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChecked: false,
            id: null
        }

    }

    componentDidMount() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
        console.log(this.props.location.state);
    }

    handleChecked = (value) => {
        this.setState({
            isChecked: value,
            id: uuidv4()
        })

    }

    handleClickBack = () => {
        this.props.history.push({
            pathname: '/home'
        })
    }

    handleClickStart = () => {
        this.props.history.replace({
            pathname: '/queue',
            state: {
                "uuid": this.state.id,
                "firstname": this.props.location.state.firstname,
                "lastname": this.props.location.state.lastname,
                "phone": this.props.location.state.phone,
                "email": this.props.location.state.email,
                "private_ip": this.props.location.state.private_ip
            }
        })
    }


    render() {
        return (
            <>
                {/* <LogoCustom src={Logo} className="image-logo"/>
                <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                <DivContainer className='item-center'>
                    <ImageBG>
                        <FormInput>
                            <DivImageHeader>
                                <ImageHeader src={ConditionsHeader} />
                            </DivImageHeader>
                            {/* <DivContentAds className='pb-10' custom={isMobileDevice()}>
                                <img src={ConditionsHeader} />
                            </DivContentAds> */}
                            <DivContentCondition className='align-self-center o-auto pl-20 pr-20 zIdex-2'>
                                <p className='text-center pt-10'>
                                    <span className='text-bold'>ความยินยอมในการประมวลผลข้อมูลส่วนบุคคลสำหรับลูกค้าและผู้มุ่งหวัง</span><br />
                                    <span className='text-bold'>บริษัท ทิพยประกันภัย จำกัด (มหาชน)</span><br /><br />
                                </p>
                                <p >

                                    <span>ข้าพเจ้า ได้อ่าน เข้าใจ และรับทราบรายละเอียดในนโยบายคุ้มครองข้อมูลส่วนบุคคล (“นโยบาย”) ของบริษัท ทิพยประกันภัย จำกัด (มหาชน) ตามรายละเอียดที่ปรากฏ ในนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัทฯ</span><br />
                                    <span>(https://www.dhipaya.co.th/files/personal</span><br />
                                    <span>_data_privacy.pdf) เป็นที่เรียบร้อยแล้ว และเข้าใจดีว่าบริษัทฯ จะเก็บรวบรวม ใช้ และเปิดเผย (“ประมวลผล”) ข้อมูลส่วนบุคคลของข้าพเจ้าโดยปฏิบัติตามนโยบายดังกล่าวซึ่งได้จัดทำขึ้นตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 (“พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล”) ข้าพเจ้าจึงให้ความยินยอมเป็นการเฉพาะในกรณีดังต่อไปนี้</span><br />

                                    <span className='text-bold'>โปรดอ่านทำความเข้าใจให้ครบถ้วนและให้ความยินยอมตามเจตนาของท่าน ทั้งนี้โปรดพิจารณาการให้ความยินยอมตามลักษณะกรมธรรม์ประกันภัยที่ท่านต้องการจะซื้อประกอบด้วย</span><br /><br />
                                    <span className='text-bold under-line'>การเก็บรวบรวมข้อมูลภาพและเสียงผ่านอุปกรณ์อิเล็กทรอนิกส์ : </span>
                                    <span>ข้าพเจ้ายินยอมให้บริษัทฯ เก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหวที่ปรากฏบนสื่ออิเล็กทรอนิกส์
                                        ในระหว่างที่มีการเสนอขายกรมธรรม์ประกันภัยของบริษัทฯ ได้แก่ ข้อมูลใบหน้า ลักษณะของบุคคล และ/หรือ พฤติกรรมที่มีลักษณะเฉพาะส่วนบุคคล เพื่อดำเนินการใด ๆ ที่จำเป็นเพื่อใช้เป็นหลักฐานสำหรับการเข้าทำสัญญาประกันภัย การพิสูจน์ยืนยันตัวตน และการปฏิบัติตามสัญญาประกันภัยที่มีระหว่างข้าพเจ้าและบริษัทฯ รวมถึงการดำเนินการอื่นใดที่จำเป็นในเรื่องที่เกี่ยวข้องดังกล่าว ทั้งนี้บริษัทฯ ขอยืนยันว่าบริษัทฯ ไม่มีความประสงค์จะเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหวดังกล่าวนอกเหนือจากวัตถุประสงค์ตามที่ได้แจ้งไว้ เพียงแต่ข้อมูลส่วนบุคคลที่มีความอ่อนไหวดังกล่าวอาจปรากฏอยู่ในระบบเทคโนโลยีสารสนเทศที่บริษัทฯ จำเป็นต้องเก็บรวบรวมเท่านั้น</span><br /><br />
                                </p>
                                <p className='text-center'>
                                    <span className='text-bold'>กรณีผู้สมัครเอาประกันภัยอายุต่ำกว่า 20 ปีบริบูรณ์ ความยินยอมของผู้มีอำนาจปกครองของเจ้าของข้อมูลส่วนบุลคค (บิดา มารดา หรือผู้ปกครองตามคำสั่งศาล)</span><br />
                                </p>
                                <p className='pb-10'>
                                    <span>ข้าพเจ้า ซึ่งเป็นผู้ลงลายมือชื่อ (อิเล็กทรอนิกส์) ด้านล่างได้อ่าน เข้าใจ และรับทราบรายละเอียดที่ปรากฏในนโยบายของบริษัทฯ เกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลของบุคคลภายใต้การปกครองของข้าพเจ้าแล้ว ข้าพเจ้าจึงให้ความยินยอมแก่บริษัทฯ ในการประมวลผลข้อมูลส่วนบุคคลของบุคคลภายใต้ปกครองของข้าพเจ้าตามรายละเอียดความยินยอมข้างต้น</span><br />
                                </p>
                            </DivContentCondition>
                            <DivRadio className='bottom-10-per zIdex-2'>
                                <RadioCustom

                                    aria-labelledby="demo-radio-buttons-group-label"
                                    onChange={(e) => this.handleChecked(e.target.checked)}
                                    name="radio-buttons-group"
                                >
                                    <FormControlLabel
                                        className="flex-start-radio"
                                        control={
                                            <Radio
                                                // className="flex-start-radio"
                                                checked={this.state.isChecked}
                                                checkedIcon={<ImgRadio src={Checked} />}
                                                icon={<ImgRadio src={Unchecked} />}
                                                onChange={(e) => this.handleChecked(e.target.checked)}
                                            />}
                                        label={<SpanCustom>ท่านยินยอมให้บริษัทฯ ทำการบันทึกการสนทนาผ่านบริการ Video Call ตามวัตถุประสงค์ข้างต้น</SpanCustom>} />
                                </RadioCustom>
                            </DivRadio>
                            <DivContent>
                                {/* <Link to="/home"> */}
                                {/* <ButtonBackCustom src={ButtonBackActive} onClick={() => this.handleClickBack()} /> */}
                                {/* </Link> */}
                                {
                                    this.state.isChecked ?
                                        // <Link to={{
                                        //     pathname: "/queue",
                                        //     state: {
                                        //         "uuid": this.state.id,
                                        //         "firstname": this.props.location.state.firstname,
                                        //         "lastname": this.props.location.state.lastname,
                                        //         "phone": this.props.location.state.phone,
                                        //         "email": this.props.location.state.email
                                        //     },
                                        // }}>
                                        <SVCButtonCustom isClick={this.state.isChecked} src={ButtonNextActive} onClick={() => this.handleClickStart()} />
                                        // </Link>
                                        :

                                        <SVCButtonCustom src={ButtonNext} />

                                }
                            </DivContent>
                        </FormInput>
                    </ImageBG>
                </DivContainer>

            </>
        )
    }
}