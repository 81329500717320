import styled from 'styled-components';
import ButtonActive from '../../assets/image/Button_Next_Active.png'

export const TextHeader = styled.p`
    font-size: 40px;
`;

export const SubText = styled.span`
    font-size: 40px;
    color: white;
    text-shadow: none;
`;


export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20vh;
`;

export const DivContentAds = styled.div`
   flex : 1;
   text-align: center;
   padding-top: rem;
`;

export const DivContentQueue = styled.div`
   flex : 1;
   text-align: center;
    padding-top: 3%;
    padding-bottom: 2%;
    margin-bottom: -20px;
    margin-top: -20px;
    @media screen and (max-width: 350px) and (min-width: 300px) {
        padding-top: 0%;
        padding-bottom: 0%;
    }
`;


export const DivContent = styled.div`
   flex : 1;
   /* padding-top: 5%; */
   text-align: center;
    width: 100%;
    color: white;
    text-align: -webkit-center;
    padding-bottom: 10%;
    @media screen and (max-width: 350px) and (min-width: 300px) {
        padding-top: 0%;
        padding-bottom: 0%;
    }
`;

export const SpecialText = styled.p`
    font-size: 50px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: red;
`

export const ButtonNext = styled.img`
    img :hover {
        background-image: ButtonActive;
    }
`

export const LogoCustom = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 13vh;
    padding: 5vw;
`

export const ImgFingerCustom = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 13vh;
    max-width: 45vw;
`

export const Image1Custom = styled.img`
    max-width: 186px;   
    width: 30vw;
    align-self: center;
`

export const DivLogo = styled.div`
    padding-top: 3%;
    padding-right: 8%;
    display: flex;
    align-self: flex-end;
`

export const TextQueue = styled.p`
    font-size: 32px;
    textShadow: 1px 1px 0.5rem #1b1717;
    @media screen and (max-width: 430px) {
        font-size: 20px;
    }
`

export const RotateImage = styled.img`
    width: 20vw;
    max-width: 90px;
    animation: rotation 1.5s infinite linear;
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media screen and (max-width: 430px) {
        width: 28vw;
    }
`

export const Divqueue = styled.div`
    align-items: center;
    background-color: white;
    color: #7E7F82;
    width: 154px;
    height: 52px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    font-size: 26px;
`;

export const DivImageHeader = styled.div`
    padding-top: 20px;
    text-align: center;
    @media screen and (max-width: 430px) {
        padding-top: 0;
    }
`

export const ImageHeader = styled.img`
    margin-top: 4vh;
    margin-bottom: 22px;
    width: 36vw;
    max-width: 280px;
    @media screen and (max-width: 430px) {
        margin-top: 4vh;
        width: 60vw;
    }
`

export const FormInput = styled.div`

`
export const ImageBG = styled.div`
    max-width: 400px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    width: 62vw;
    min-height: 57vh;
    height: fit-content;
    color: #ffffff;
    z-index: 1;
    background-color: #4c6dff75;
    border-radius: 24px;
    box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    @media screen and (max-width: 430px) {
        width: 90vw;
    }
`