import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Button, Grid, FormControl, InputLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { Wrapper, ErrorMessage, LoginButton, ForgotPasswordMassage, SizeText, Container, TextLogin } from './style-component'
import Services from '../../services/agentServices'

var load

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: false,
            username: '',
            password: '',
            remember: false
        }

    }

    componentDidMount() {
        // const urlParams = new URLSearchParams(window.location.search);
        // let tokenParam = urlParams.get('token')
        // if (tokenParam) {
        //     console.log('push to video call agent');
        //     localStorage.setItem("token", tokenParam)
        //     Services.opreator_getOperationStatus().then((res) => {
        //         console.log(res.data);
        //         this.props.history.push({
        //             pathname: "/agent-videocall",
        //             state: res.data.opreator
        //         });
        //     })
        // }
    }

    handleSubmit = () => {
        let obj = {
            "username": this.state.username,
            "password": this.state.password
        }
        // history.push('/form');
        // this.props.history.push('/form');
        Services.operator_login(obj).then((res) => {
            console.log(res.data);
            this.props.history.push({
                pathname: `/?token=${res.data.access_token}`,
            });
            window.location.reload();
        })
    }

    handleChangeUsername = (value, name) => {
        this.setState({
            username: value,
        })
        // if (this.state.errorDescription != '') {
        //     this.setState({
        //         errorDescription: '',
        //         errorMessage: null
        //     })
        // }
    }

    handleChangePassword = (value, name) => {
        this.setState({
            password: value,
        })
        // if (this.state.errorDescription != '') {
        //     this.setState({
        //         errorDescription: '',
        //         errorMessage: null
        //     })
        // }
    }

    render() {
        return (
            <Container >
                <Wrapper container className="h-100" direction="column" justify="center" alignItems="center">
                    <Grid container item xs={7} sm={6} md={5} lg={4} xl={3}>
                        <ValidatorForm
                            ref="form"
                            onSubmit={() => this.handleSubmit()}
                            onError={errors => console.log(errors)}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextLogin>Log in</TextLogin>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl margin="dense" fullWidth={true}>
                                        <TextValidator
                                            className='w-100'
                                            type="text"
                                            label="Username"
                                            onChange={(e) => this.handleChangeUsername(e.target.value, e.target.name)}
                                            name="username"
                                            value={this.state.username}
                                            validators={['required']}
                                            errorMessages={['Username is a required field']}
                                            variant="filled"
                                            autoFocus={true}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl margin="dense" fullWidth={true}>
                                        <TextValidator
                                            className='w-100'
                                            type="password"
                                            label="Password"
                                            onChange={(e) => this.handleChangePassword(e.target.value, e.target.name)}
                                            name="password"
                                            value={this.state.password}
                                            validators={['required']}
                                            errorMessages={['Password is a required field']}
                                            variant="filled"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={6} justify="flex-start">
                                        <FormControlLabel className="text-white"
                                            control={
                                                <SizeText
                                                    checked={this.state.remember}
                                                    onChange={() => {
                                                        this.setState({
                                                            remember: !this.state.remember
                                                        })
                                                    }}
                                                    value={this.state.remember}
                                                    color="primary"
                                                />
                                            }
                                            label="Remember me?"
                                        />
                                    </Grid>
                                    <Grid item xs={6} justify="flex-end">
                                        {/* <ForgotPasswordMassage className="pointer" onClick={() => this.handleForgotPassword()}>Forgot Password?</ForgotPasswordMassage> */}
                                    </Grid>

                                </Grid>

                                <Grid item xs={12} alignItems="center">

                                    <FormControl margin="dense" fullWidth={true}>
                                        <LoginButton type="submit" color="primary" variant="contained">Log In</LoginButton>
                                    </FormControl>

                                </Grid>
                                <Grid item xs={12} alignItems="center">
                                    {
                                        this.state.errorDescription && <ErrorMessage>{this.state.errorDescription}</ErrorMessage>

                                    }
                                    {
                                        this.state.isErrorLogin && <ErrorMessage>{this.state.errorMessage}</ErrorMessage>
                                    }
                                </Grid>

                            </Grid>
                        </ValidatorForm>
                    </Grid>
                </Wrapper>
            </Container>
        )
    }
}