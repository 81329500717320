import React, { Component } from 'react'
import { DivContainer, ImageDeviceNotSupport, ImageBG } from './style-component'
import ContentDeviceNotSupport from '../../assets/image/content-device-not-support.png'
import { LogoCustom, ImgFingerCustom } from '../../util'
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import BGMobile from '../../assets/image/bg-mobile.png'

export default class DeviceNotSupport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 555
        }

    }

    componentDidMount() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
    }

    nextPage = () => {
    }


    render() {
        return (
            <React.Fragment>
                {/* <LogoCustom src={Logo} className="image-logo" />
                <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                <DivContainer className='item-center'>
                    <ImageBG>
                        <ImageDeviceNotSupport src={ContentDeviceNotSupport} alt="thank you" className='zIdex-2'/>
                    </ImageBG>
                </DivContainer>
            </React.Fragment >


        )
    }
}