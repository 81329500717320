import React, { Component } from 'react'
import { DivContainer, DivContent, RotateImage, DivContentQueue, Image1Custom, Divqueue,
    ImageBG, FormInput, DivImageHeader, ImageHeader, TextQueue, LogoCustom, ImgFingerCustom } from './style-component'
import CallingHeader from '../../assets/image/Calling.svg'
import Loading from '../../assets/image/Spinner.png'
import Informative from '../../assets/image/InformativeII.png'
import Services from '../../services/'
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import BGMobile from '../../assets/image/bg-mobile.png'


var load

export default class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            count: 0,
        }

    }

    componentDidMount() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
        navigator.mediaDevices.enumerateDevices().then(devices => 
            devices.forEach(device => {
                console.log('device.label', device.label)
                if (device.label) {
                } else {
                    navigator.mediaDevices
                        .getUserMedia({
                            video: true,
                            audio: true
                        })
                        .then((stream) => {
    
                        })
                        .catch((e) => {
                            this.props.history.push('/not-allow-camera-and-microphone')
                        })
                }
            }))
        var isDone = true
        console.log(this.props.location.state);
        let obj = {
            "uuid": this.props.location.state.uuid,
            // "uuid": "9eea7923-c5c0-41b7-8fbc-4445e3d3530a",
            "firstname": this.props.location.state.firstname,
            "firstname": this.props.location.state.firstname,
            "lastname": this.props.location.state.lastname,
            "phone": this.props.location.state.phone,
            "email": this.props.location.state.email,
            "private_ip": this.props.location.state.private_ip,
        }

        load = setInterval(() => {
            if (isDone) {
                isDone = false
                Services.customer_getMyQueue(obj).then((res) => {
                    console.log(res);
                    if (res.data.status == 200) {
                        isDone = true
                        if (res.data.watingQueue == 0 && res.data.operator_id != null) {
                            clearInterval(load)
                            this.props.history.replace({
                                pathname: '/videocall',
                                state: {
                                    "uuid": this.props.location.state.uuid,
                                    // "uuid": "8a215025-ec50-44f4-b0e5-faa48e12836f",
                                    "firstname": this.props.location.state.firstname,
                                    "lastname": this.props.location.state.lastname,
                                    "phone": this.props.location.state.phone,
                                    "email": this.props.location.state.email,
                                    "private_ip": this.props.location.state.private_ip,
                                    "operator_id": res.data.operator_id
                                }
                            })
                        } else {
                            isDone = true
                            this.setState({
                                count: res.data.watingQueue
                            })
                        }
                    }else{
                        isDone = true
                    }
                })
            }
        }, 1500)
    }

    nextPage = () => {
        console.log("ss");
    }



    render() {
        return (
            <>
                {/* <LogoCustom src={Logo} className="image-logo" />
                <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                <DivContainer className='item-center'>
                    <ImageBG>
                        <FormInput>
                             <DivImageHeader>
                                <ImageHeader src={CallingHeader} />
                            </DivImageHeader>
                            <DivContentQueue className="zIdex-2">
                                <RotateImage src={Loading} />
                            </DivContentQueue>
                            <Image1Custom src={Informative} className="zIdex-2"/>
                            <DivContent className="zIdex-2">
                                <TextQueue>มีผู้รอรับบริการก่อนหน้าท่าน</TextQueue>
                                {/* <img src={TextWait} /> */}
                                <Divqueue className='mt-10'>
                                    {'จำนวน ' +this.state.count + ' คน'}
                                </Divqueue>
                            </DivContent>
                        </FormInput>
                    </ImageBG>
                </DivContainer>
            </>

        )
    }
}