import axios from 'axios'
import config from '../config/config.json'
import https from 'https';



const HTTP_GET = 'GET'
const HTTP_POST = 'POST'

class Services {

    operator_login(requestData) {
        return this.sendRequest(config.protocal + config.server + '/token', HTTP_POST, requestData);
    }

    opreator_getOperationStatus() {
        return this.sendRequest(config.protocal + config.server + '/operator/getStatus', HTTP_GET);
    }

    operator_setOnline(requestData) {
        return this.sendRequest(config.protocal + config.server + '/operator/opAction', HTTP_POST, requestData);
    }

    operator_setBusy(requestData) {
        return this.sendRequest(config.protocal + config.server + '/operator/opAction', HTTP_POST, requestData);
    }

    operator_getMyCustomerOnWaitingCall(requestData) {
        return this.sendRequest(config.protocal + config.server + '/operator/opAction', HTTP_POST, requestData);
    }

    operator_startCall_Calling(requestData){
        return this.sendRequest(config.protocal + config.server + '/operator/startCall', HTTP_POST, requestData);
    }

    operator_endCall_HangUp(requestData) {
        return this.sendRequest(config.protocal + config.server + '/operator/endCall', HTTP_POST, requestData);
    }

    operator_cancelWaiting_Cancel(requestData) {
        return this.sendRequest(config.protocal + config.server + '/operator/opAction', HTTP_POST, requestData);
    }

    operator_getCustomerOnWaitingCall() {
        return this.sendRequest(config.protocal + config.server + '/operator/getWaitingQueue', HTTP_GET);
    }

     sendRequest(url, method, requestData) {
        
        var requestObject = {
            headers: { Authorization: localStorage.getItem("token") },
            method: method,
            url: `${url}`,
            header: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json; charset=utf-8"
            }
        }

        // console.log(requestObject);
        if (method != HTTP_GET) {
            requestObject.data = requestData
        }
        return axios(requestObject)
            .then((res) => {
                // console.log('pass', res);
                const { data, status } = res;
                return { data, status };
            }, (error) => {
                console.log("error 1", error);
                const { data, status, statusText } = error;
                throw { data, status, statusText };
            })
            .catch((error) => {
                console.log("catch error 1", error);
                throw error
            })
    }
} export default new Services()