import React, { Component } from 'react'
import { DivContainer, ImageBG, ImageNotWorkingTime, LogoCustom, ImgFingerCustom } from './style-component'
import ContentNotWorkingTime from '../../assets/image/content-not-working-time-v2.png'
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import BGMobile from '../../assets/image/bg-mobile.png'
import moment from 'moment-timezone';


export default class NotWorkingTime extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 555
        }

    }

    componentDidMount() {
        // console.log('current', moment().tz('Asia/Bangkok').format("HH:mm:ss"))
        // console.log('close time', moment().tz('Asia/Bangkok').format("18:00:00"))
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
    }

    nextPage = () => {
    }


    render() {
        return (
            <React.Fragment>
                {/* <LogoCustom src={Logo} className="image-logo" />
                <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                <DivContainer className='item-center'>
                    <ImageBG>
                        <ImageNotWorkingTime src={ContentNotWorkingTime} alt="thank you" className='zIdex-2'/>
                    </ImageBG>
                </DivContainer>
            </React.Fragment >


        )
    }
}