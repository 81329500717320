import { Button } from '@material-ui/core'
import { Link } from "react-router-dom";
import React, { Component } from 'react'
import { DivTest } from './style-component'

export default class BlankPageM extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    componentDidMount() {

    }

    render() {
        return (
            <DivTest >
                <p>กรุณาตรวจสอบการอนุญาตเข้าถึงกล้องและไมโครโฟน</p>

                <Button onClick={() => {
                    this.props.history.push({
                        pathname: "/"
                    })
                }}>ตกลง
                </Button>


            </DivTest>
        )
    }
}