import styled from 'styled-components';
import { TextField, Grid } from '@material-ui/core';

export const TextHeader = styled.p`
    font-size: 40px;
`;

export const SubText = styled.span`
    font-size: 40px;
    color: white;
    text-shadow: none;
`;


export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20vh;
`;

export const DivContentAds = styled.div`
    position: relative;
    justify-content: center;
    display: flex;
`;
export const DivContent = styled.div`
    text-align: center;
    position: absolute;
    bottom: -3.5vh;
    width: 100%;
    z-index: 3;
`;

export const SpecialText = styled.p`
    font-size: 50px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: red;
`

export const DivLogo = styled.div`
    padding-top: 3%;
    padding-right: 8%;
    display: flex;
    align-self: flex-end;
`

export const TextFieldinput = styled(TextField)`
    input {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 3px;
        /* font-size: 1.1em;  */ 
        background-color : #FFFFFF;
        border-radius: 5px;
        height: 30px;
        color : #2b2b2b;
    }
    fieldset {
        border-radius: 5px;
    }
    width: 100%;
`;

export const GridCustom = styled(Grid)`
    text-align: center;
`;

export const SpanRed = styled.span`
    color: #FF0000;
`

export const ButtomNextDisabled = styled.img`
    width: 19vw;
    min-width: 120px;
    max-width: 139px;
`

export const ButtonCustom = styled.img`
    width: 19vw;
    min-width: 120px;
    max-width: 139px;
`

export const ButtonBackCustom = styled.img`
    width: 19vw;
    min-width: 120px;
    max-width: 139px;
`

export const ErrorText = styled.p`
    color: red;
    width: 100%;
    margin-bottom: 10px;
`
export const ImageHeader = styled.img`
    padding: 0 5vw;
    margin-top: 4vh;
    margin-bottom: 1vh;
    @media screen and (max-width: 430px) {
        margin-top: 4vh;
        width: 67vw;
    }
`

export const TextLabelInput = styled.p`
    width: 100%;
    margin-bottom: 10px;
    font-size: 26px;
    @media screen and (max-width: 430px) {
        font-size: 20px;
        margin-bottom: 6px;
    }
`

export const FormInput = styled.div`

`

export const LogoCustom = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 13vh;
    padding: 5vw;
`

export const ImgFingerCustom = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 13vh;
    max-width: 45vw;
`

export const ImageBG = styled.div`
    padding-bottom: 56px;
    max-width: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    width: 62vw;
    min-height: 40vh;
    height: fit-content;
    color: #ffffff;
    z-index: 1;
    background-color: #4c6dff75;
    border-radius: 24px;
    box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    @media screen and (max-width: 430px) {
        width: 90vw;
    }
`