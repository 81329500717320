import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const GridScreenShare = styled(Grid)`
    height: 60vh;
    background-color: white;
`;

export const IconCustom = styled.img`
    width: 72px;
    height: ${props => props.height ? props.height : "35.34px"};
`;

export const DivContent = styled.div`
    flex : 1;
    height: 50px;
    position: fixed;
    bottom: 0%;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #003183;
`;

export const ScreenAgent = styled.div`
    width: 100%;
    height: 83vh;
    background-color: aquamarine;
    position: relative;
`

export const ScreenUser = styled.div`
    width: 35%;
    border-radius: 4px 4px 4px 4px;
    height: 170px;
    background-color: black;
    position: absolute;
    bottom: 0;
    right: 0;
`

export const TextCustom = styled.p`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-family: 'Roboto', sans-serif;
    font-weight: ${props => props.bold && "bold"};
`
export const DivAbsoluteParant = styled.div`
    position: relative;
`

export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    display: ${props => props.isPartnerAccept ? 'flex' : "none"};
    flex-direction: column;
    padding-top: 20vh;
`;

export const DivContentAds = styled.div`
    flex : 1;
    text-align: center;
    padding-top: rem;
`;

export const DivImageHeader = styled.div`
    padding-top: 20px;
    text-align: center;
    @media screen and (max-width: 430px) {
        padding-top: 0;
    }
`

export const ImageHeader = styled.img`
    margin-top: 4vh;
    margin-bottom: 22px;
    width: 36vw;
    max-width: 280px;
    @media screen and (max-width: 430px) {
        margin-top: 4vh;
        width: 60vw;
    }
`

export const DivContentQueue = styled.div`
    flex : 1;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 2%;
    @media screen and (max-width: 350px) and (min-width: 300px) {
        padding-top: 0%;
    }
`;

export const RotateImage = styled.img`
    width: 89.82px;
    height: 89.82px;
    animation: rotation 1.5s infinite linear;
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
`

export const LogoCustom = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 13vh;
    padding: 5vw;
`

export const ImgFingerCustom = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 13vh;
    max-width: 45vw;
`

export const Image1Custom = styled.img`
    max-width: 200px;
    width: 30vw;
    align-self: center;
`

export const TextHeader = styled.p`
     font-size: 300%;
    -webkit-text-stroke: 0.5px white;
    color: red;
`;

export const GridButton = styled(Grid)`
    position: absolute;
    bottom: 50px;

    right: 0;
`;

export const GridTop = styled(Grid)`
    position: absolute;
    top: 0;
    height: 74%;
    margin-top: 40px;
    padding-top: ${props => props.orientation == "landscape" ? "7%" : ""};

`;

export const GridBackground = styled.div`
    background: #003183;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const DivEmpty = styled.div`
  width: 300px;
  /* border: 15px solid green; */
  padding: 50px;
  margin: 20px;
`;

export const TextQueue = styled.p`
    margin-bottom: 8px;
    font-size: 32px;
    textShadow: 1px 1px 0.5rem #1b1717;
    @media screen and (max-width: 430px) {
        font-size: 20px;
    }
`

export const Divqueue = styled.div`
    align-items: center;
    background-color: #41b619;
    color: white;
    // width: 154px;
    height: 52px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    font-size: 26px;
    min-width: 154px;
`;

export const DivqueueEnd = styled.div`
    align-items: center;
    background-color: white;
    color: #7E7F82;
    width: 154px;
    height: 52px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    font-size: 26px;
`;


export const DivHeader = styled.div`
    background-color: #003183BF;
`;

export const ImageBG = styled.div`
    max-width: 400px;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    width: 62vw;
    min-height: 57vh;
    height: fit-content;
    color: #ffffff;
    z-index: 1;
    background-color: #4c6dff75;
    border-radius: 24px;
    box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    @media screen and (max-width: 430px) {
        width: 90vw;
    }
`

export const BGShareScreen = styled.div`
    background-color: black;
    height: 100%;
    align-items: center;
    display: flex;
    borderRadius: 1rem;
    transform: ${props => props.shareFlag ? "scale(1)" : "unset"};
`;