import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { DivContainer, DivLogo, LogoCustom } from './style-component'
import { Provider } from 'mobx-react'
import Home from '../Home/'
import Form from '../Form/'
import TermConditions from '../Ageement/'
import WaitQueue from '../WaitQueue/'
import VideoCall from '../videocall'
import VideoCallKios from '../videocallKios'
import AgentVideoCall from '../videocallAgent'
import Thanks from '../Thanks'
import DeviceNotSupport from '../DeviceNotSupport'
import DeviceNotAllowCameraAndMicrophone from '../DeviceNotAllowCameraAndMicrophone'
import { createBrowserHistory } from "history";
import {isMobileDevice} from '../../util'
import BlankPage from '../BlankPage/'
import BlankPageM from '../BlankPageMoblie/'
import Login from '../Login'
import NotWorkingTime from '../NotWorkingTime';
import moment from 'moment-timezone';

const history = createBrowserHistory();
export default class Site extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isHide: 0
        }
    }

    componentDidMount() {
        if (window.location.search.includes('version')) {
            localStorage.setItem('isDebugMode', 'true')
            document.getElementById('version').style.display = 'block'
        }
        
        if (['/home', '/terms-conditions', '/queue', '/thank-you'].some(each =>  window.location.pathname.includes(each)) || window.location.pathname == '/') {

            fetch(`https://tipucme-api.dhipaya.co.th/time`, {
                "method": 'GET'
            })
                .then(response => response.text())
                .then(result => {
                    let resParsed = JSON.parse(result)
                    let timeServer = moment()
                    if (resParsed.now) timeServer = moment(resParsed.now)
                    if (timeServer.format("HH:mm:ss") < timeServer.format("10:00:00") || timeServer.format("HH:mm:ss") > timeServer.format("18:00:00")) {
                        document.location.replace("/not-working-time");
                    }
                })
                .catch(error => {
                    alert(JSON.stringify(error))
                });
        }
    }

    render() {
        return (
            <Provider >
                <Router history={history}>
                    <DivContainer>
                        {/* {isMobileDevice() &&
                         
                            <DivLogo style={{ display: window.location.pathname == '/videocall' ? "none" : "flex" }}>
                                <LogoCustom isBehind = {window.location.pathname == '/videocall'} src={Logo} />
                            </DivLogo>
                            
                        } */}
                        <div id="version" style={{ display: 'none', color: '#ffffff', position: 'absolute', bottom: '2vh' }}> version 08111703</div>
                        <Switch>
                 
                            <Route exact path="/" component={Home} />
                            <Route path="/home" component={Form} />
                            <Route path="/terms-conditions" component={TermConditions} />
                            <Route path="/queue" component={WaitQueue} />
                            <Route path="/videocall" component={VideoCall} />
                            {/* <Route path="/manage-kios" component={ManageQueue} /> */}
                            <Route path="/kios-videocall" component={VideoCallKios} />
                            <Route path="/login" component={Login} />
                            <Route path="/agent-videocall" component={AgentVideoCall} />
                            <Route path="/thank-you" component={Thanks} />
                            <Route path="/device-not-support" component={DeviceNotSupport} />
                            <Route path="/blank" component={BlankPage} />
                            <Route path="/not-allow-camera-and-microphone" component={DeviceNotAllowCameraAndMicrophone} />
                            <Route path="/not-working-time" component={NotWorkingTime} />
                        </Switch>
                    </DivContainer>
                </Router>
            </Provider>
        )
    }
}