import React, { Component } from 'react'
import { Link } from "react-router-dom";
import {
    DivContainer, DivContent, ButtonCustom, DivContentAds, DivCenter, DivTest,
    LogoCustom, ImageAdsContent, ImgFingerCustom
} from './style-component'
import TextService from '../../assets/image/Text-service.png'
import ButtonActive from '../../assets/image/Button_Next_Active.png'
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import Services from '../../services/agentServices'
import Services_ from '../../services/index'
import BGMobile from '../../assets/image/bg-mobile.png'
import { v4 as uuidv4 } from "uuid";

var load

export default class WaitQueue extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: false
        }

    }

    componentDidMount() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
        // navigator.mediaDevices
        //     .getUserMedia({
        //         video: true,
        //         audio: true
        //     })
        //     .then((stream) => {

        //     })
        //     .catch((e) => {
        //         this.props.history.push('/not-allow-camera-and-microphone')
        //     })
        const urlParams = new URLSearchParams(window.location.search);
        let tokenParam = urlParams.get('token')
        if (tokenParam) {
            console.log('push to video call agent');
            localStorage.setItem("token", tokenParam)
            Services.opreator_getOperationStatus().then((res) => {
                console.log(res.data);
                this.props.history.push({
                    pathname: "/agent-videocall",
                    state: res.data.opreator
                });
            })
            // console.log(tokenParam);

        }

        let kiosParam = urlParams.get('kios')
        if (kiosParam) {
            this.setState({
                page: true
            })
            console.log('push to video call kios');
            // localStorage.setItem("kios", kiosParam)
            // console.log(kiosParam);
            var isDone = true

            //console.log(this.props.location.state);
            let uuid = uuidv4()
            let obj = {
                "uuid": uuid,
                // "uuid": "9eea7923-c5c0-41b7-8fbc-4445e3d3530a",
                // "firstname": this.props.location.state.firstname ? "":"ddd",
                "firstname": "kios",
                "lastname": "test",
                "phone": "5555",
                "email": "email",
                "private_ip": kiosParam
            }
            console.log(obj);
            load = setInterval(() => {
                if (isDone) {
                    isDone = false
                    Services_.customer_getMyQueue(obj).then((res) => {
                        console.log(res);
                        if (res.data.status == 200) {
                            isDone = true
                            if (res.data.watingQueue == 0 && res.data.operator_id != null) {
                                clearInterval(load)
                                this.props.history.push({
                                    pathname: '/kios-videocall',
                                    state: {
                                        "uuid": uuid,
                                        // "uuid": "8a215025-ec50-44f4-b0e5-faa48e12836f",
                                        "firstname": "kios",
                                        "lastname": "test",
                                        "phone": "5555",
                                        "email": "email",
                                        "operator_id": res.data.operator_id
                                    }
                                })
                                // alert("I got queue");
                                window.location.reload();

                            }
                        }
                    })
                }
            }, 1500)
        }
    }

    nextPage = () => {

        // history.push('/form');
        // this.props.history.push('/form');
    }


    render() {
        return (
            <React.Fragment>
                {
                    this.state.page ?
                        <DivTest >
                            <DivCenter>
                                <i className="fa fa-spinner fa-pulse fa-3x fa-fw text-color-white"></i>
                            </DivCenter>
                        </DivTest>
                        :
                        <>
                            {/* <LogoCustom src={Logo} className="image-logo" />
                            <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                            <DivContainer>
                                <DivContentAds>
                                    <ImageAdsContent src={TextService} alt="ads" />
                                    <DivContent>
                                        <div onClick={() => {
                                            window.newTab = {}
                                            window.newTab['1'] = window.open()
                                            window.newTab['1'].location = `/home/`+window.location.search
                                            // document.location.replace('/home/'+window.location.search)
                                            document.location.replace("about:blank");
                                            // window.close()
                                        }}>
                                            <ButtonCustom src={ButtonActive} />
                                        </div>
                                    </DivContent>
                                </DivContentAds>
                            </DivContainer>
                        </>
                }


            </React.Fragment>
        )
    }
}