import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const GridScreenShare = styled(Grid)`
    height: 60vh;
    background-color: white;
`;

export const IconCustom = styled.img`
    /* width: 72px;
    height: ${props => props.height ? props.height : "35.34px"}; */
`;

export const DivContent = styled.div`
    flex : 1;
    height: 50px;
    position: fixed;
    bottom: 0%;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: #003183;
`;

export const ScreenAgent = styled.div`
    width: 100%;
    height: 83vh;
    background-color: aquamarine;
    position: relative;
`

export const ScreenUser = styled.div`
    width: 35%;
    border-radius: 4px 4px 4px 4px;
    height: 170px;
    background-color: black;
    position: absolute;
    bottom: 0;
    right: 0;
`

export const TextCustom = styled.p`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-family: 'Roboto', sans-serif;
    font-weight: ${props => props.bold && "bold"};
`

export const TextCustomKios = styled.p`
    color: ${props => props.color};
    font-size: ${props => props.size};
    font-family: 'Roboto', sans-serif;
    font-weight: ${props => props.bold && "bold"};
    align-self: center;
    padding-bottom: 10%;
`

export const DivAbsoluteParant = styled.div`
    position: relative;
`

export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: ${props => props.isPartnerAccept ? 'flex' : "none"};
    flex-direction: column;
    /* background-color: rgba(76, 175, 80, 0.1); */

`;

export const DivContentAds = styled.div`
   flex : 1;
   text-align: center;
   padding-top: 36%;
   @media screen and (max-width: 350px) and (min-width: 300px) {
        padding-top: 2%;
    }
   /* @media screen and (max-width: 350px) and (min-width: 300px) {
        padding-top: 7%;
    } */
`;

export const DivContentQueue = styled.div`
   flex : 1;
   text-align: center;
   padding-top: 5%;
    padding-bottom: 2%;
    @media screen and (max-width: 350px) and (min-width: 300px) {
        padding-top: 0%;
    }
`;

export const RotateImage = styled.img`
    width: 89.82px;
    height: 89.82px;
    animation: rotation 1.5s infinite linear;
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
`

export const Image1Custom = styled.img`
    width: 199.75px;
    align-self: center;
    padding-bottom: 10px;
`

export const TextHeader = styled.p`
     font-size: 300%;
    -webkit-text-stroke: 0.5px white;
    color: red;
`;

export const GridButton = styled(Grid)`
    position: absolute;
    bottom: 50px;

    right: 0;
`;

export const GridTop = styled(Grid)`
    position: absolute;
    top: 0;
    height: 74%;
    margin-top: 40px;
`;

export const GridBackground = styled(Grid)`
    background-color: #003183;
    padding-top: 5px;
    position: fixed;
    bottom: 0%;
    width: ${props => props.orientation == "landscape" ? '50%' : '100%'};
`;

export const DivEmpty = styled.div`
  width: 300px;
  /* border: 15px solid green; */
  padding: 50px;
  margin: 20px;
`;

export const Divqueue = styled.div`
    align-items: center;
   color: white;
   border-radius: 4px;
   display: flex;
    justify-content: center;
`;

export const DivqueueEnd = styled.div`
    align-items: center;
    background-color: white;
    color: gray;
    width: 154px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
`;


export const DivHeader = styled(Grid)`
    background-color: #003183BF;
`;

export const DivTest = styled.div`
           height: 100vh;
        width: 100%;
        /* position: absolute; */
        background-color: #043183;
        overflow: hidden !important;
        z-index: inherit;
        //background-image: url("./assets/image/background.png");
`;


export const RightSideKios = styled.div`
    background-color: rgb(72, 105, 165);
    display: flex;
    flex-direction: column;
    padding: 10%;
    border-radius: 0% 5% 5% 0%;
`;