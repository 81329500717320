import axios from 'axios'
import config from '../config/config.json'
import https from 'https';



const HTTP_GET = 'GET'
const HTTP_POST = 'POST'

class Services {

    customer_getMyQueue(requestData) {
        return this.sendRequest(config.protocal + config.server + '/queue/getMyQueue', HTTP_POST, requestData);
    }

    customer_startCall(requestData) {
        return this.sendRequest(config.protocal + config.server + '/queue/startCall', HTTP_POST, requestData);
    }

    customer_endCall(requestData) {
        return this.sendRequest(config.protocal + config.server + '/queue/endCall', HTTP_POST, requestData);
    }

     sendRequest(url, method, requestData) {
        
        var requestObject = {
            method: method,
            url: `${url}`,
            header: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json; charset=utf-8"
            },
            httpsAgent: new https.Agent({
                rejectUnauthorized: false
            })
        }

        // console.log(requestObject);
        if (method != HTTP_GET) {
            requestObject.data = requestData
        }
        return axios(requestObject)
            .then((res) => {
                // console.log('pass', res);
                const { data, status } = res;
                return { data, status };
            }, (error) => {
                console.log("error 1", error);
                const { data, status, statusText } = error;
                throw { data, status, statusText };
            })
            .catch((error) => {
                console.log("catch error 1", error);
                throw error
            })
    }
} export default new Services()