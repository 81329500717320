import styled from 'styled-components';
import { Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';

export const Wrapper = styled(Grid)`
    padding: 10px;
`;

export const Container = styled.div`
	/* margin: 1rem; */
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	/* justify-content: start;
	align-items: center;*/
	background-color: white; 
`;


export const Logo = styled.img`
    width: 50vh;
`;

export const LoginButton = styled(Button)`
    &.MuiButton-containedPrimary {
        color: #ffffff;
        text-transform: unset;
        font-size: 16px;
        font-weight: bold;
        background-color: #5F4BD2;
    }
`;

export const ForgotPasswordMassage = styled.p`
    margin: 9px;
    text-align: right;
    color: #f44336;
    text-decoration: underline;
`

export const ErrorMessage = styled.p`
    color: #f44336;
`;

export const ErrorMessageResponse = styled.p`
    display: flex;
    flex: 1;
    justify-content: center;
    color: #f44336;
`

export const boxMassage = styled.div`
    border-radius: 4px !important;
    color : black;

`
export const boxColor = styled.div`
    border-radius: 4px !important;
    background-color: rgb(255, 88, 88);
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`

export const MuiSnackbarContentMessage = styled.div`
    padding: 13px 12px 13px 8px;
`

export const Icon = styled.i`
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
`

export const FontLabelError = styled.label`
    font-size: 14px;
    padding-left: 10px;
`
export const SizeText = styled(Checkbox)`
    @media only screen and (max-width: 50%){
        font-size: 10px;
    }
`

export const TextLogin = styled.b`
    font-size: 30px;
    color: #293252;
`
