import styled from 'styled-components';

export const DivTest = styled.div`
        /* position: absolute; */
        height: 200vh;
        width: 100%;
        background-color: white;
        color: black;
        overflow: hidden;
        text-align: center;
        display: inline-block;
        padding-top: 60%;
`;