import styled from 'styled-components';


export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
`;

export const DivLogo = styled.div`
    padding-top: 3%;
    padding-right: 8%;
    display: flex;
    align-self: flex-end;
`

export const LogoCustom = styled.img`
    width: 83px;
    height: 25px;  
    opacity: ${props => props.isBehind && '0.3'};
`