import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { DivContainer, ImageHeader, DivContent, TextFieldinput, SpanRed, ButtomNextDisabled, ButtonCustom, ButtonBackCustom, ErrorText,
    ImageBG, TextLabelInput, FormInput, LogoCustom, ImgFingerCustom } from './style-component'
import RegisterHeader from '../../assets/image/Register.svg'
import Logo from '../../assets/image/uc-logo.png'
import ImgFinger from '../../assets/image/finger.png'
import ButtonNextActive from '../../assets/image/Button_Next_Active.png'
import ButtonBackActive from '../../assets/image/Button_Back.png'
import ButtonNext from '../../assets/image/Button_Next.png'
import BGMobile from '../../assets/image/bg-mobile.png'
let isFirstThai = false;
let isLastThai = false;
let isPhoneLength = false;
let isEmailNotCorrect = false;

export default class Form extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            isFilled: false,
            isEmpty: {
                firstName: false,
                lastName: false,
                phone: false,
                email: false
            },
            isEmpty: false,
            isFocus: false,
            isFirstThai: false,
            isLastThai: false,
            isPhoneLength: false,
            isEmailCorrect: false

        }

    }

    componentDidMount() {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test( navigator.userAgent ); //resolves true/false
        if (isMobile) {
            document.getElementsByTagName('body')[0].style.backgroundImage = `url(${BGMobile})`;
        }
    }

    onChangeData = (name, value) => {
        switch (name) {
            case 'firstName':
                isFirstThai = false;
                this.setState({
                    firstName: value,
                    isFirstThai: false
                })
                break;
            case 'lastName':
                isLastThai = false
                this.setState({
                    lastName: value,
                    isLastThai: false
                })
                break;
            case 'phone':
                isPhoneLength = false;
                this.setState({
                    phone: value,
                    isPhoneLength: false
                })
                break;
            case 'email':
                isEmailNotCorrect = false
                this.setState({
                    email: value,
                    isEmailCorrect: false
                })
                break;
        }
        this.setState({
            isEmpty: false
        })
        if (this.state.firstName != '' && this.state.lastName != '' && this.state.phone != '' && this.state.email != '') {
            this.setState({
                isFilled: true
            })
        } else {
            this.setState({
                isFilled: false
            })
        }
        this.setState({
            isFilled: true
        })
    }

    nameValidation = () => {
        let regexNameEng = /^[a-zA-Z]+$/;
        if (regexNameEng.test(this.state.firstName)) {
            isFirstThai = true;
            this.setState({ isFirstThai: true })
        }
        else {
            isFirstThai = false;
            this.setState({ isFirstThai: false })
        }

        if (regexNameEng.test(this.state.lastName)) {
            isLastThai = true
            this.setState({ isLastThai: true })
            
        }
        else {
            isLastThai = false
            this.setState({ isLastThai: false })
        }
    }

    phoneValidation = () => {
        if (this.state.phone.length < 10) {
            isPhoneLength = true;
            this.setState({ isPhoneLength: true })
        }
        else {
            isPhoneLength = false;
            this.setState({ isPhoneLength: false })
        }
    }

    emailValidation = () => {
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (!regexEmail.test(this.state.email) && this.state.email != "") {
            isEmailNotCorrect = true
            this.setState({ isEmailCorrect: true })
        }
        else {
            isEmailNotCorrect = false
            this.setState({ isEmailCorrect: false })
        }
    }

    handleClickNext = () => {
        this.nameValidation()
        this.phoneValidation()
        this.emailValidation()
        // console.log("isFirstThai", isFirstThai)
        // console.log("isLastThai", isLastThai)
        // console.log("isPhoneLength", isPhoneLength)
        // console.log("isEmailNotCorrect", isEmailNotCorrect)
        if (this.state.firstName != "" && this.state.lastName != "" && this.state.phone != "" && !isFirstThai && !isLastThai && !isPhoneLength && !isEmailNotCorrect) {
            const url = new URL(window.location.href);
            url.searchParams.get('local')
            this.props.history.replace({
                pathname: "/terms-conditions",
                state: {
                    "firstname": this.state.firstName,
                    "lastname": this.state.lastName,
                    "phone": this.state.phone,
                    "email": this.state.email,
                    "private_ip": url.searchParams.get('local') || ''
                }
            })
            // console.log("pass")
        } else {
            // console.log("not pass")
            if (this.state.firstName == "") {
                this.setState({ isEmpty: { firstName: true } })
            } else if (this.state.lastName == "") {
                this.setState({ isEmpty: { lastName: true } })
            } else if (this.state.phone == "") {
                this.setState({ isEmpty: { phone: true } })
            } else { }
        }
        // console.log("ss  ", this.state.firstName, this.state.lastName, this.state.phone, this.state.email);
    }

    hadleOnFocus = () => {
        this.setState({
            isFocus: true
        })
    }

    hadleOnBlur = () => {
        this.setState({
            isFocus: false
        })
    }

    render() {
        return (
            <>
                {/* <LogoCustom src={Logo} className="image-logo" />
                <ImgFingerCustom src={ImgFinger} className="image-finger" /> */}
                <DivContainer className='item-center'>
                    {/* <DivContentAds custom={isMobileDevice()} className='pb-10' >
                    </DivContentAds> */}
                    <ImageBG>
                        <FormInput>
                            <ImageHeader src={RegisterHeader} />
                            <div className='pb-20' style={{ maxWidth: 328, margin: 'auto' }}>
                                <TextLabelInput>ชื่อ - นามสกุล<SpanRed>*</SpanRed></TextLabelInput>
                                <TextFieldinput className='pb-10' placeholder="ระบุชื่อ" variant="outlined" name="firstName" value={this.state.firstName} onChange={(e) => this.onChangeData(e.target.name, e.target.value)} onFocus={() => this.hadleOnFocus()} onBlur={() => this.hadleOnBlur()} />
                                <br />
                                <TextFieldinput placeholder="ระบุนามสกุล" variant="outlined" name="lastName" value={this.state.lastName} onChange={(e) => this.onChangeData(e.target.name, e.target.value)} onFocus={() => this.hadleOnFocus()} onBlur={() => this.hadleOnBlur()} />
                                <br />
                                {this.state.isEmpty.firstName && <ErrorText>*กรุณาใส่ชื่อ</ErrorText>}
                                {this.state.isEmpty.lastName && <ErrorText>*กรุณาใส่นามสกุล</ErrorText>}
                                {(isFirstThai || isLastThai) ? <ErrorText>*ชื่อ-นามสกุลต้องเป็นภาษาไทยเท่านั้น</ErrorText> : ""}

                            </div>
                            <div className='pb-20' style={{ maxWidth: 328, margin: 'auto' }}>
                                <TextLabelInput>หมายเลขโทรศัพท์ติดต่อ<SpanRed>*</SpanRed></TextLabelInput>
                                <TextFieldinput placeholder="ระบุหมายเลขติดต่อกลับ" variant="outlined" name="phone" type="number" value={this.state.phone} onFocus={(e) => this.setState({ isFilled: true })} onChange={(e) => this.onChangeData(e.target.name, e.target.value)} onFocus={() => this.hadleOnFocus()} onBlur={() => this.hadleOnBlur()} />
                                <br />
                                {this.state.isEmpty.phone && <ErrorText>*กรุณาใส่หมายเลขโทรศัพท์ติดต่อ</ErrorText>}
                                {isPhoneLength && <ErrorText>*หมายเลขโทรศัพท์มือถือต้องมี 10 หลัก</ErrorText>}
                            </div>
                            <div style={{ maxWidth: 328, margin: 'auto' }}>
                                <TextLabelInput>อีเมล์</TextLabelInput>
                                <TextFieldinput placeholder="ระบุอีเมล์" variant="outlined" name='email' value={this.state.email} onChange={(e) => this.onChangeData(e.target.name, e.target.value)} onFocus={() => this.hadleOnFocus()} onBlur={() => this.hadleOnBlur()} />
                                <br />
                                {isEmailNotCorrect && <ErrorText>อีเมล์ไม่ถูกต้อง</ErrorText>}
                            </div>
                        </FormInput>
                        <DivContent>
                            <Link to="/">
                                <ButtonBackCustom src={ButtonBackActive} />
                            </Link>
                            {
                                this.state.isFilled ?
                                    // <Link to={{
                                    //     pathname: "/terms-conditions",
                                    //     state: {
                                    //         "firstname": this.state.firstName,
                                    //         "lastname": this.state.lastName,
                                    //         "phone": this.state.phone,
                                    //         "email": this.state.email
                                    //     },
                                    // }}>
                                    <ButtonCustom className='pl-40' src={ButtonNextActive} onClick={() => this.handleClickNext()} />
                                    // </Link>
                                    :

                                    <ButtomNextDisabled className='pl-40' src={ButtonNext} />

                            }
                        </DivContent>
                    </ImageBG>
                </DivContainer>
            </>

        )
    }
}