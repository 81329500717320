import React, { Component } from 'react'
import {DivTest} from './style-component'

export default class BlankPage extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }

    }

    componentDidMount() {

    }

    render() {
        return (
            <DivTest >

            </DivTest>
        )
    }
}