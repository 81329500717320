import styled from 'styled-components';
import ButtonActive from '../../assets/image/Button_Next_Active.png'

export const TextHeader = styled.p`
    font-size: 40px;
`;

export const SubText = styled.span`
    font-size: 40px;
    color: white;
    text-shadow: none;
`;


export const DivContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 20vh;
`;

export const DivContentAds = styled.div`
    position: relative;
    justify-content: center;
    display: flex;
`;
export const DivContent = styled.div`
    text-align: center;
    position: absolute;
    bottom: -4vh;
    width: 100%;
    z-index: 3;
`;

export const SpecialText = styled.p`
    font-size: 50px;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: red;
`

export const ButtonNext = styled.img`
    img :hover {
        background-image: ButtonActive;
    }
`

export const ButtonCustom = styled.img`
    max-width: 226px;
    width: 35vw;
    min-width: 220px;
`
export const ImageThanks = styled.img`
    max-width: 507px;
    position: absolute;
    width: 60vw;
    @media screen and (max-width: 430px) {
        width: 90vw;
    }
`

export const DivLogo = styled.div`
    padding-top: 3%;
    padding-right: 8%;
    display: flex;
    align-self: flex-end;
`

export const ImageAds = styled.img`
    width: 300px;
`

export const LogoCustom = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    height: 13vh;
    padding: 5vw;
`

export const ImgFingerCustom = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 13vh;
    max-width: 45vw;
`

export const ImageBG = styled.div`
    max-width: 400px;
    text-align: center;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 62vw;
    min-height: 57vh;
    height: fit-content;
    color: #ffffff;
    z-index: 1;
    background-color: #4c6dff75;
    border-radius: 24px;
    box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 6px 5px 8px -2px rgba(0,0,0,0.75);
    @media screen and (max-width: 430px) {
        width: 90vw;
    }
`